<template>
  <form class="k-form signUp findPasswordStep4 pd0" id="signupFormStep" @submit.prevent="onSubmit(userPwd, userId);">
    <p class="etext">비밀번호 재설정시 아래 내용에 주의해 주세요.</p>
    <p class="lnote2"><span class="k-icon k-i-warning"></span>비밀번호 재설정시 주의사항 </p>
    <p class="fsmall">기존에 사용하셨던 비밀번호로 동일설정이 불가능합니다.<br/>비밀번호는 8자 이상의 영문, 숫자, 특수문자 조합으로 작성해주세요
    </p>
    <fieldset>
      <label class="k-form-field">
        <kendo-maskedtextbox
            type="password"
            ref="userPwdRef"
            minlength="8"
            maxlength="50"
            v-model.trim="userPwd"
            placeholder="※8~20글자 영문,숫자,특수문자조합"
            class="w100"
        >
        </kendo-maskedtextbox>
      </label>
      <label class="k-form-field">
        <kendo-maskedtextbox
            type="password"
            ref="userPwdConfirmRef"
            minlength="8"
            maxlength="50"
            v-model.trim="userPwdConfirm"
            placeholder="비밀번호와 동일하게 입력해주세요."
            class="w100"
        >
        </kendo-maskedtextbox>
      </label>
      <input type="submit" value="확인" class="mid_btn orange login_btn" style="cursor: pointer;">
    </fieldset>
  </form>
</template>

<script>
  export default {
    name: 'FindPasswordStep3',
    props: {
      param: {
        type: Object,
        required: false
      }
    },
    methods: {
      chkIdCompare: function (str, id) {

        const passwordCharArr = []
        let result = 0
        for (let i = 0; i < str.length - 2; i++) {
          passwordCharArr.push(str.charAt(i) + str.charAt(i + 1) + str.charAt(i + 2))
        }

        passwordCharArr.forEach(function (item) {
          if (id.indexOf(item) !== -1) {
            result = result + 1
          }
        })

        return result === 0
      },
      onSubmit (userPwd) {
        const vm = this

        if(!vm.userPwd.trim()) {
          kendo.alert('새비밀번호를 입력해 주세요.').bind('close', function() {
            vm.$refs.userPwdRef.kendoWidget().element[0].focus()
          })
          return false
        }
        if(!vm.userPwdConfirm.trim()) {
          kendo.alert('새비밀번호확인을 입력해 주세요.').bind('close', function() {
            vm.$refs.userPwdConfirmRef.kendoWidget().element[0].focus()
          })
          return false
        }
        if (!this.chkIdCompare(userPwd, this.userId)) {
          kendo.alert('아이디와 3자 이상 중복되면 안됩니다.')
          return false
        }
        if (userPwd !== this.userPwdConfirm) {
          kendo.alert('새 비밀번호와 맞지 않습니다.<br>기재한 비밀번호를 확인해주세요.')
          return false
        }
        const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/.test(userPwd)
        if (!matched) {
          kendo.alert('비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.')
          return false
        }


        this.$store.dispatch('FETCH_UPDATE_PASSWORD', { userId: this.userId, userPwd }).then(response => {
          this.$emit('child')
        })
      },
    },
    data () {
      return {
        userId: this.$props.param.userId,
        userPwd: '',
        userPwdConfirm: '',
      }
    },
  }
</script>
